import { CredentialsModel } from '@/models/credentials-model';
import { RefreshTokenModel } from '@/models/refresh-token-model';
import axios from 'axios';
import ConfigProvider from '@/services/config-provider';

const API_BASE_URL = ConfigProvider.value('apiBaseUri');

class AuthService {
  login(credentials: CredentialsModel) {
    return axios
      .post(API_BASE_URL + '/auth/login', credentials, { withCredentials: true })
      .then(response => {
        if (response.data.authToken)
          localStorage.setItem('user', JSON.stringify(response.data));

        return response.data;
      })
      .catch(error => {
        localStorage.removeItem('user');
        throw error;
      });
  }

  logout() {
    const data = new RefreshTokenModel();

    return axios
      .post(API_BASE_URL + '/auth/revoketoken', data, { withCredentials: true })
      .then(() => {
        localStorage.removeItem('user');
      })
      .catch(error => {
        localStorage.removeItem('user');
        throw error;
      });
  }

  refreshToken() {
    const data = new RefreshTokenModel();

    return axios
      .post(API_BASE_URL + '/auth/refreshtoken', data, { withCredentials: true })
      .then(response => {
        if (response.data.authToken)
          localStorage.setItem('user', JSON.stringify(response.data));

        return response.data;
      })
      .catch(error => {
        localStorage.removeItem('user');
        throw error;
      });
  }

  requestToken(authorizationCode: string) {
    const data = {
      authorizationCode: authorizationCode
    };

    return axios
      .post(API_BASE_URL + '/auth/requesttoken', data, { withCredentials: true })
      .then(response => {
        if (response.data.authToken)
          localStorage.setItem('user', JSON.stringify(response.data));

        return response.data;
      })
      .catch(error => {
        localStorage.removeItem('user');
        throw error;
      });
  }

  createAccount(email: string, firstName: string, lastName: string, password: string) {
    return axios.post(API_BASE_URL + '/accounts', { email, firstName, lastName, password });
  }
}

export default new AuthService();
