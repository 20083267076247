import axios from 'axios';
import { AuthHeader } from '@/services/jwt-service';
import { State } from '@/store/index';
import { ListCardItem } from '@/models/list-card-item';
import { PresentationSessionModel } from '@/models/presentation-session-model';
import { PixelFrontEndContainerInfoModel } from '@/models/pixel-frontend-container-info-model';
import { PlayerMachineModel } from '@/models/player-machine-model';
import ConfigProvider from '@/services/config-provider';

const API_BASE_URL = ConfigProvider.value('apiBaseUri') + '/presentationsessions';
const PIXEL_STREAMING_CHECK_TAG = '<title>SecuREview - Live Stream</title>';

class PresentationSessionsService {
  async getAll(headers: AuthHeader) {
    return await axios
      .get(API_BASE_URL, { headers })
      .then(response => {
        if (response.data)
          return (response.data.items as Array<PresentationSessionModel>)
            .map(item => this.deserialize(item));

        return [];
      });
  }

  async add(session: PresentationSessionModel, headers: AuthHeader) {
    return await axios
      .post(API_BASE_URL, session, { headers })
      .then(response => {
        if (response.data)
          return this.deserialize(response.data);
        
        return undefined;
      });
  }

  async update(session: PresentationSessionModel, headers: AuthHeader) {
    return await axios
      .put(API_BASE_URL + '/' + session.id, session, { headers })
      .then(response => {
        if (response.data)
          return this.deserialize(response.data);
        
        return undefined;
      });
  }

  async delete(id: number, headers: AuthHeader) {
    return await axios
      .delete(API_BASE_URL + '/' + id, { headers })
      .then(response => {
        if (response.status === 200)
          return true;
        
        return false;
      });
  }

  getSessionById(state: State, id: number): PresentationSessionModel | undefined {
    if (state.sessions)
      return state.sessions.find(session => session.id === id);
    
    return undefined;
  }

  getSessionByJoinToken(state: State, sessionJoinToken: string): PresentationSessionModel | undefined {
    if (state.sessions)
      return state.sessions.find(session => session.sessionJoinToken === sessionJoinToken);
    
    return undefined;
  }

  sessionsList(state: State): ListCardItem[] {
    if (state.sessions)
      return state.sessions
      .slice()
      .sort((s1, s2) => {
        if (s1.startDate > s2.startDate)
          return 1;
        else if (s1.startDate === s2.startDate)
          return 0;
        else
          return -1;
      })
    .map(item => this.fillListCardItem(state, item));
    
    return [];
  }

  sessionsListForProjectId(state: State, projectId: number): ListCardItem[] {
    if (state.sessions)
      return state.sessions
      .slice()
      .filter(s => s.projectId === projectId)
      .sort((s1, s2) => {
        if (s1.startDate > s2.startDate)
          return 1;
        else if (s1.startDate === s2.startDate)
          return 0;
        else
          return -1;
      })
    .map(item => this.fillListCardItem(state, item));
    
    return [];
  }

  mostRecentSessionsList(state: State): ListCardItem[] {
    const now = new Date();

    if (state.sessions)
      return state.sessions
        .slice()
        .filter(s => s.endDate >= now)
        .sort((s1, s2) => {
          if (s1.startDate > s2.startDate)
            return 1;
          else if (s1.startDate === s2.startDate)
            return 0;
          else
            return -1;
        })
        .slice(0, 3)
        .map(item => this.fillListCardItem(state, item));
    
    return [];
  }

  createNewSession(projectId?: number): PresentationSessionModel {
    return new PresentationSessionModel(0, projectId ? projectId : 0, '', new Date(), new Date(), '');
  }

  async getRunningSessions(headers: AuthHeader) {
    return await axios
      .get(API_BASE_URL + '/running', { headers })
      .then(response => {
        if (response.data)
          return (response.data.items as Array<PixelFrontEndContainerInfoModel>)
            .map(item => this.deserializeRunningSessions(item));

        return [];
      });
  }

  async startSession(id: number, headers: AuthHeader) {
    return await axios.get(API_BASE_URL + '/' + id + '/start', { headers });
  }

  async stopSession(id: number, headers: AuthHeader) {
    return await axios.get(API_BASE_URL + '/' + id + '/stop', { headers });
  }

  async checkSessionIsReady(sessionJoinToken: string): Promise<boolean> {
    const pixelBaseUri = ConfigProvider.value('pixelBaseUri');
    const fullUri = pixelBaseUri + sessionJoinToken + `/?ts=${new Date().getTime()}`;

    try {
      return await axios
      .get(fullUri, { timeout: 5000 }) // Timeout of 5 seconds.
      .then(response => {
        if (response.data) {
          const htmlContent = response.data as string;
          return (htmlContent.indexOf(PIXEL_STREAMING_CHECK_TAG) > 0);
        }

        return false;
      });
    }
    catch (error) {
      return false;
    }
  }

  openSession(sessionJoinToken: string): void {
    const pixelBaseUri = ConfigProvider.value('pixelBaseUri');
    const jitsiBaseUri = ConfigProvider.value('jitsiBaseUri');

    const pixelUri = pixelBaseUri + sessionJoinToken + `/?ts=${new Date().getTime()}`;
    const jitsiUri = jitsiBaseUri + sessionJoinToken;

    window.open(pixelUri, '_blank');

    if (jitsiBaseUri.length > 0)
      window.location.replace(jitsiUri);
  }

  async getPlayerMachinesStatus(headers: AuthHeader) {
    return await axios
      .get(API_BASE_URL + '/machinesstatus', { headers })
      .then(response => {
        if (response.data)
          return (response.data.items as Array<PlayerMachineModel>)
            .map(item => this.deserializePlayerMachines(item));

        return [];
      });
  }

  async resetPlayerMachinesStatus(headers: AuthHeader) {
    return await axios
      .post(API_BASE_URL + '/resetmachinesstatus', {}, { headers });
  }

  private fillListCardItem(state: State, item: PresentationSessionModel): ListCardItem {
    let subTitle = undefined;

    if (item.projectId && state.projects) {
      const project = state.projects.find(project => project.id === item.projectId);

      if (project)
        subTitle = project.name;
    }

    return new ListCardItem(item.id.toString(), item.startDate.toLocaleDateString() + ' - ' + item.title, subTitle);
  }

  private deserialize(item: PresentationSessionModel): PresentationSessionModel {
    return new PresentationSessionModel(
      item.id,
      item.projectId,
      item.title,
      new Date(item.startDate),
      new Date(item.endDate),
      item.sessionJoinToken,
      item.description);
  }

  private deserializeRunningSessions(item: PixelFrontEndContainerInfoModel): PixelFrontEndContainerInfoModel {
    return new PixelFrontEndContainerInfoModel(
      item.presentationSessionId,
      item.sessionJoinToken,
      item.userName,
      new Date(item.createdAt));
  }

  private deserializePlayerMachines(item: PlayerMachineModel): PlayerMachineModel {
    return new PlayerMachineModel(
      item.name,
      item.isOffline);
  }
}

export default new PresentationSessionsService();
