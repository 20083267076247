export interface AuthHeader {
  Authorization?: string;
}

export interface JwtModel {
  audience: string;
  issuer: string;
  expirationTime: number;
  notValidBefore: number;
  issuedAt: number;
  jwtId: string;
  
  id: string;
  subject: string;
  name: string;
  givenName: string;
  role: string;

  authToken: string;
}

export class JwtService {
  data?: JwtModel;

  constructor()
  {
    const authToken = this.localAuthToken();

    if (authToken)
      this.parseJwt(authToken);
  }

  parseJwt(token?: string): void {
    if (!token || token.length == 0)
      this.data = undefined;
    else {
      const base64Url = token.split('.')[1];
      const base64 = decodeURIComponent(atob(base64Url).split('').map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
  
      const jwtData = JSON.parse(base64);

      this.data = {
        audience: jwtData['aud'],
        issuer: jwtData['iss'],
        expirationTime: jwtData['exp'],
        notValidBefore: jwtData['nbf'],
        issuedAt: jwtData['iat'],
        jwtId: jwtData['jti'],
        
        id: jwtData['id'],
        subject: jwtData['sub'],
        name: jwtData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'],
        givenName: jwtData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'],
        role: jwtData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'],

        authToken: token,
      };
    }
  }
  
  isTokenExpired(): boolean {
    const currentTime: number = Math.round((new Date()).getTime() / 1000);
    const validDuration: number = this.data == undefined ? 0 : this.data.expirationTime - currentTime;
    return validDuration <= 0;
  }

  isAdmin(): boolean {
    return this.data != undefined && this.data.role.toLowerCase() === 'admin';
  }

  resetData(): void {
    this.data = undefined;
  }

  authHeader(): AuthHeader {
    if (this.data && this.data.authToken)
      return { Authorization: 'Bearer ' + this.data.authToken };
    
    return {};
  }

  userFullName(): string {
    return this.data ? this.data.givenName + ' ' + this.data.name : '';
  }

  private localAuthToken(): string | undefined {
    const storedUser = localStorage.getItem('user');
  
    if (storedUser) {
      const user = JSON.parse(storedUser);
    
      if (user && user.authToken)
        return user.authToken;
    }
  
    return undefined;
  }
}
