import axios, { AxiosError } from 'axios';

export interface HttpExtendedResult {
  status: number;
  title: string;
  type?: string;
  traceId?: string;
  errors?: Record<string, string[]>;
  isCancel: boolean;
}

export function parseHttpExtendedResult(error: AxiosError): HttpExtendedResult {
  const message =
    (error.response && error.response.data && error.response.data.message) ||
    error.message ||
    error.toString();

  const result: HttpExtendedResult = { status: 500, title: message, isCancel: axios.isCancel(error) };

  if (error.response) {
    result.status = error.response.status;

    if (error.response.data) {
      if (error.response.data.type)
        result.type = error.response.data.type;

      if (error.response.data.title)
        result.title = error.response.data.title;

      if (error.response.data.status)
        result.status = error.response.data.status;

      if (error.response.data.traceId)
        result.traceId = error.response.data.traceId;

      if (error.response.data.errors)
        result.errors = error.response.data.errors;
    }
  }

  return result;
}
