<template>
  <NavBar v-if="showNavBar" />
  <router-view/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import NavBar from '@/components/NavBar.vue';

export default defineComponent({
  name: 'App',
  components: {
    NavBar,
  },

  computed: {
    showNavBar(): boolean {
      return this.$route.path != "/signin" && this.$route.path != "/meet";
    },
  }
});
</script>
