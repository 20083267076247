import dotenv from 'dotenv';
dotenv.config();

export default class ConfigProvider {
  public static get CONFIG(): Record<string, string> {
    return {
      apiBaseUri: '$VUE_APP_API_BASE_URI',
      casBaseUri: '$VUE_APP_CAS_BASE_URI',
      pixelBaseUri: '$VUE_APP_PIXEL_BASE_URI',
      jitsiBaseUri: '$VUE_APP_JITSI_BASE_URI',
      testRtcUri: '$VUE_APP_TESTRTC_URI',
      unsecuredRefreshToken: '$VUE_APP_UNSECURED_REFRESH_TOKEN',
      defaultUserName: '$VUE_APP_DEFAULT_USERNAME',
      defaultPassword: '$VUE_APP_DEFAULT_PASSWORD',
      displayDemoWarning: '$VUE_APP_DISPLAY_DEMO_WARNING',
    }
  }

  public static value(name: string): string {
    if (!(name in this.CONFIG)) {
      return '';
    }
    
    const value = this.CONFIG[name];

    if (!value) {
      return '';
    }

    if (value.startsWith('$VUE_APP_')) {
      const envName = value.substr(1);
      const envValue = process.env[envName];

      if (envValue) {
        return envValue;
      }
      else {
        return '';
      }
    }
    else {
      return value;
    }
  }
}
