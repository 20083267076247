export class AccountModel {
  id: string;
  role: string;
  email: string;
  firstName: string;
  lastName: string;

  constructor (id: string, role: string, email: string, firstName: string, lastName: string)
  { 
    this.id = id;
    this.role = role;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
  }
}
