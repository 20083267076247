export class PresentationSessionModel {
  id: number;
  projectId: number;
  title: string;
  description?: string;
  startDate: Date;
  endDate: Date;
  sessionJoinToken: string;

  constructor (id: number, projectId: number, title: string, startDate: Date, endDate: Date, sessionJoinToken: string, description?: string)
  { 
    this.id = id;
    this.projectId = projectId;
    this.title = title;
    this.description = description;
    this.startDate = startDate;
    this.endDate = endDate;
    this.sessionJoinToken = sessionJoinToken;
    this.description = description;
  }
}
