export class ProjectModel {
  id: number;
  name: string;
  description?: string;
  ownedByUserId?: string;

  constructor (id: number, name: string, description?: string, ownedByUserId?: string)
  { 
    this.id = id;
    this.name = name;
    this.description = description;
    this.ownedByUserId = ownedByUserId;
  }
}
