import axios from 'axios';
import { AuthHeader } from '@/services/jwt-service';
import { State } from '@/store/index';
import { ListCardItem } from '@/models/list-card-item';
import { ThreeDimensionalModel } from '@/models/three-dimensional-model';
import { ThreeDimensionalModelUploadPayload } from '@/models/three-dimensional-model-upload-payload';
import ConfigProvider from '@/services/config-provider';

const API_BASE_URL = ConfigProvider.value('apiBaseUri') + '/threedimensionalmodels';

class ThreeDimensionalModelService {
  async getAll(headers: AuthHeader) {
    return await axios
      .get(API_BASE_URL, { headers })
      .then(response => {
        if (response.data)
          return (response.data.items as Array<ThreeDimensionalModel>)
            .map(item => this.deserialize(item));

        return [];
      });
  }

  async add(model: ThreeDimensionalModel, headers: AuthHeader) {
    return await axios
      .post(API_BASE_URL, model, { headers })
      .then(response => {
        if (response.data)
          return this.deserialize(response.data);
        
        return undefined;
      });
  }

  async update(model: ThreeDimensionalModel, headers: AuthHeader) {
    return await axios
      .put(API_BASE_URL + '/' + model.id, model, { headers })
      .then(response => {
        if (response.data)
          return this.deserialize(response.data);
        
        return undefined;
      });
  }

  async delete(id: number, headers: AuthHeader) {
    return await axios
      .delete(API_BASE_URL + '/' + id, { headers })
      .then(response => {
        if (response.status === 200)
          return true;
        
        return false;
      });
  }

  async upload(payload: ThreeDimensionalModelUploadPayload, authToken: string) {
    return await axios
      .post(API_BASE_URL + '/' + payload.modelId + "/upload", payload.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + authToken
        },
        cancelToken: payload.cancelToken.token,
        onUploadProgress: (event) => payload.progressCallback(event)
      })
      .then(response => {
        if (response.data)
          return this.deserialize(response.data);
        
        return undefined;
      });
  }

  getModelById(state: State, id: number): ThreeDimensionalModel | undefined {
    if (state.models)
      return state.models.find(model => model.id === id);
    
    return undefined;
  }

  modelsList(state: State): ListCardItem[] {
    if (state.models)
      return state.models
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(item => this.fillListCardItem(state, item));
    
    return [];
  }

  modelsListForProjectId(state: State, projectId: number): ListCardItem[] {
    if (state.models)
      return state.models
        .slice()
        .filter(m => m.projectId === projectId)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(item => this.fillListCardItem(state, item));
    
    return [];
  }

  mostRecentModelsList(state: State): ListCardItem[] {
    if (state.models)
      return state.models.slice()
        .reverse().slice(0, 3)
        .map(item => this.fillListCardItem(state, item));
    
    return [];
  }

  createNewModel(projectId?: number): ThreeDimensionalModel {
    return new ThreeDimensionalModel(0, projectId ? projectId : 0, '');
  }

  private fillListCardItem(state: State, item: ThreeDimensionalModel): ListCardItem {
    let subTitle = undefined;

    if (item.projectId && state.projects) {
      const project = state.projects.find(project => project.id === item.projectId);

      if (project)
        subTitle = project.name;
    }

    return new ListCardItem(item.id.toString(), item.name, subTitle);
  }

  private deserialize(item: ThreeDimensionalModel): ThreeDimensionalModel {
    return new ThreeDimensionalModel(
      item.id,
      item.projectId,
      item.name,
      item.description,
      item.mainFilePath,
      item.mainFileType,
      item.mainFileSize,
      item.thumbnail);
  }
}

export default new ThreeDimensionalModelService();
