<template>
  <nav class="navbar fixed-top navbar-expand-md navbar-light">
    <router-link to="/" class="navbar-brand">
      <img alt="Assystem logo" src="@/assets/logo.png" width="43" height="30" class="d-inline-block align-top">
      SecuREview
    </router-link>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <div class="navbar-nav mr-auto">
        <router-link to="/" class="nav-item nav-link">Dashboard</router-link>
        <router-link to="/projects" :class="{ active: isProjects }" class="nav-item nav-link">Projects</router-link>
        <router-link to="/sessions" class="nav-item nav-link">Sessions</router-link>
        <router-link to="/accounts" class="nav-item nav-link" v-if="userIsAdmin">Accounts</router-link>
        <router-link to="/account" class="nav-item nav-link" v-else>Account</router-link>
        <router-link to="/admin" class="nav-item nav-link" v-if="userIsAdmin">Admin</router-link>
      </div>

      <form class="form-inline">
        <button class="btn btn-outline-danger" type="submit" @click.prevent="onSignout()" v-bind:disabled="callingApi">
          <font-awesome-icon v-if="callingApi" icon="spinner" spin />
          Sign out
        </button>
      </form>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/action-types';

export default defineComponent({
  name: 'NavBar',

  setup() {
    const store = useStore();
    return { store };
  },

  computed: {
    callingApi(): boolean { return this.store.state.callingApi; },
    userIsAdmin(): boolean { return this.store.getters.userIsAdmin; },

    // Vue Router doc says: "The default active class matching behavior is inclusive match."
    // In that case, it doesn't work. This little hack solve the problem.
    isProjects(): boolean { return this.$route.path.indexOf('/projects') === 0; },
  },

  methods: {
    onSignout() {
      this.store.dispatch(ActionTypes.LOGOUT).then(
        () => this.$router.push({ name: 'Signin' }),
        (error) => {
          alert(error.title + '\r\nhttp code: ' + error.status);
          this.$router.push({ name: 'Signin' });
        }
      );
    },
  }
});
</script>
