export class ThreeDimensionalModel {
  id: number;
  projectId: number;
  name: string;
  description?: string;
  mainFilePath?: string;
  mainFileType?: string;
  mainFileSize?: number;
  thumbnail?: string;

  constructor (id: number, projectId: number, name: string, description?: string, mainFilePath?: string, mainFileType?: string, mainFileSize?: number, thumbnail?: string)
  { 
    this.id = id;
    this.projectId = projectId;
    this.name = name;
    this.description = description;
    this.mainFilePath = mainFilePath;
    this.mainFileType = mainFileType;
    this.mainFileSize = mainFileSize;
    this.thumbnail = thumbnail;
  }
}
