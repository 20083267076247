import { InjectionKey } from 'vue';
import { createStore, useStore as baseUseStore, Store } from 'vuex';
import { MutationTypes } from './mutation-types';
import { ActionTypes } from './action-types';
import { PresentationSessionModel } from '@/models/presentation-session-model';
import { ListCardItem } from '@/models/list-card-item';
import { ProjectModel } from '@/models/project-model';
import { ThreeDimensionalModel } from '@/models/three-dimensional-model';
import { ThreeDimensionalModelUploadPayload } from '@/models/three-dimensional-model-upload-payload';
import { AccountModel } from '@/models/account-model';
import { RegistrationModel } from '@/models/registration-model';
import { ChangePasswordModel } from '@/models/change-password-model';
import { PixelFrontEndContainerInfoModel } from '@/models/pixel-frontend-container-info-model';
import { PlayerMachineModel } from '@/models/player-machine-model';

import AuthService from '@/services/auth-service';
import ProjectsService from '@/services/projects-service';
import PresentationSessionService from '@/services/presentation-sessions-service';
import ThreeDimensionalModelService from '@/services/three-dimensional-model-service';
import AccountsService from '@/services/accounts-service';
import { CredentialsModel } from '@/models/credentials-model';
import { parseHttpExtendedResult } from '@/services/http-extended-result';
import { JwtService } from '@/services/jwt-service';

// Define your typings for the store state.
export interface State {
  projects?: ProjectModel[];
  sessions?: PresentationSessionModel[];
  models?: ThreeDimensionalModel[];
  accounts?: AccountModel[];

  callingApi: boolean;
  loadingData: boolean;
  loadingProjects: boolean;
  loadingSessions: boolean;
  loadingModels: boolean;
  loadingAccounts: boolean;

  isLogged: boolean;
  jwt: JwtService;
}

// Define injection key.
export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  state: {
    callingApi: false,
    loadingData: false,
    loadingProjects: false,
    loadingSessions: false,
    loadingModels: false,
    loadingAccounts: false,

    isLogged: false,
    jwt: new JwtService()
  },

  getters: {
    // User
    userFullName: (state): string => {
      return state.jwt.userFullName();
    },

    userIsAdmin: (state): boolean => {
      return state.jwt.isAdmin();
    },

    // Projects
    projects: (state): ProjectModel[] | undefined => {
      return state.projects;
    },

    projectsList: (state): ListCardItem[] => {
      return ProjectsService.projectsList(state);
    },

    mostRecentProjectsList: (state): ListCardItem[] => {
      return ProjectsService.mostRecentProjectsList(state);
    },

    getProjectById: (state) => (id: number): ProjectModel | undefined => {
      return ProjectsService.getProjectById(state, id);
    },

    // Sessions
    sessions: (state: State): PresentationSessionModel[] | undefined => {
      return state.sessions;
    },

    sessionsList: (state: State): ListCardItem[] => {
      return PresentationSessionService.sessionsList(state);
    },

    sessionsListForProjectId: (state: State) => (projectId: number): ListCardItem[] => {
      return PresentationSessionService.sessionsListForProjectId(state, projectId);
    },

    mostRecentSessionsList: (state: State): ListCardItem[] => {
      return PresentationSessionService.mostRecentSessionsList(state);
    },

    getSessionById: (state: State) => (id: number): PresentationSessionModel | undefined => {
      return PresentationSessionService.getSessionById(state, id);
    },

    getSessionByJoinToken: (state: State) => (sessionJoinToken: string): PresentationSessionModel | undefined => {
      return PresentationSessionService.getSessionByJoinToken(state, sessionJoinToken);
    },

    // Models
    models: (state): ThreeDimensionalModel[] | undefined => {
      return state.models;
    },

    modelsList: (state): ListCardItem[] => {
      return ThreeDimensionalModelService.modelsList(state);
    },

    modelsListForProjectId: (state) => (projectId: number): ListCardItem[] => {
      return ThreeDimensionalModelService.modelsListForProjectId(state, projectId);
    },

    mostRecentModelsList: (state): ListCardItem[] => {
      return ThreeDimensionalModelService.mostRecentModelsList(state);
    },

    getModelById: (state) => (id: number): ThreeDimensionalModel | undefined => {
      return ThreeDimensionalModelService.getModelById(state, id);
    },

    // Accounts
    accounts: (state): AccountModel[] | undefined => {
      return state.accounts;
    },

    accountsList: (state): ListCardItem[] => {
      return AccountsService.accountsList(state);
    },

    getAccountById: (state) => (id: string): AccountModel | undefined => {
      return AccountsService.getAccountById(state, id);
    },

    storageUsed: (state): number => {
      let storageUsed = 0;
      state.models?.forEach(model => storageUsed += model.mainFileSize ? model.mainFileSize : 0);
      return storageUsed;
    },
  },

  mutations: {
    // Projects
    [MutationTypes.SET_PROJECTS](state: State, payload: ProjectModel[]) {
      state.projects = payload;
    },

    [MutationTypes.ADD_PROJECT](state: State, payload: ProjectModel) {
      if (state.projects && payload && payload.id > 0) {
        const project = state.projects.find(project => project.id === payload.id);

        if (project == undefined)
          state.projects.push(payload);
      }
    },

    [MutationTypes.UPDATE_PROJECT](state: State, payload: ProjectModel) {
      if (state.projects && payload && payload.id > 0) {
        const project = state.projects.find(project => project.id === payload.id);

        if (project) {
          project.name = payload.name;
          project.description = payload.description;
        }
      }
    },

    [MutationTypes.DELETE_PROJECT](state: State, payload: number) {
      if (state.projects && payload > 0) {
        const index: number = state.projects.findIndex(project => project.id === payload);

        if (index > -1) {
          state.projects.splice(index, 1);
        }
      }
    },

    [MutationTypes.SET_LOADING_PROJECTS](state: State, payload: boolean) {
      state.loadingProjects = payload;
      state.loadingData = payload;
    },

    // Presentation sessions
    [MutationTypes.SET_PRESENTATION_SESSIONS](state: State, payload: PresentationSessionModel[]) {
      state.sessions = payload;
    },

    [MutationTypes.ADD_PRESENTATION_SESSION](state: State, payload: PresentationSessionModel) {
      if (state.sessions && payload && payload.id > 0) {
        const session = state.sessions.find(session => session.id === payload.id);

        if (session == undefined)
          state.sessions.push(payload);
      }
    },

    [MutationTypes.UPDATE_PRESENTATION_SESSION](state: State, payload: PresentationSessionModel) {
      if (state.sessions && payload && payload.id > 0) {
        const session = state.sessions.find(session => session.id === payload.id);

        if (session) {
          session.title = payload.title;
          session.description = payload.description;
          session.startDate = payload.startDate;
          session.endDate = payload.endDate;
          session.sessionJoinToken = payload.sessionJoinToken;
        }
      }
    },

    [MutationTypes.DELETE_PRESENTATION_SESSION](state: State, payload: number) {
      if (state.sessions && payload > 0) {
        const index: number = state.sessions.findIndex(session => session.id === payload);

        if (index > -1) {
          state.sessions.splice(index, 1);
        }
      }
    },

    [MutationTypes.DELETE_PRESENTATION_SESSIONS_FOR_PROJECT](state: State, payload: number) {
      if (state.sessions && payload > 0) {
        for (let index = state.sessions.length - 1; index >= 0; index--) {
          if (state.sessions[index].projectId === payload)
            state.sessions.splice(index, 1);
        }
      }
    },

    [MutationTypes.SET_LOADING_PRESENTATION_SESSIONS](state: State, payload: boolean) {
      state.loadingSessions = payload;
      state.loadingData = payload;
    },

    // 3D models
    [MutationTypes.SET_THREE_DIMENSIONAL_MODELS](state: State, payload: ThreeDimensionalModel[]) {
      state.models = payload;
    },

    [MutationTypes.ADD_THREE_DIMENSIONAL_MODEL](state: State, payload: ThreeDimensionalModel) {
      if (state.models && payload && payload.id > 0) {
        const model = state.models.find(model => model.id === payload.id);

        if (model == undefined)
          state.models.push(payload);
      }
    },

    [MutationTypes.UPDATE_THREE_DIMENSIONAL_MODEL](state: State, payload: ThreeDimensionalModel) {
      if (state.models && payload && payload.id > 0) {
        const model = state.models.find(model => model.id === payload.id);

        if (model) {
          model.name = payload.name;
          model.description = payload.description;
          model.mainFilePath = payload.mainFilePath;
          model.mainFileType = payload.mainFileType;
          model.mainFileSize = payload.mainFileSize;
          model.thumbnail = payload.thumbnail;
        }
      }
    },

    [MutationTypes.DELETE_THREE_DIMENSIONAL_MODEL](state: State, payload: number) {
      if (state.models && payload > 0) {
        const index: number = state.models.findIndex(model => model.id === payload);

        if (index > -1) {
          state.models.splice(index, 1);
        }
      }
    },

    [MutationTypes.DELETE_THREE_DIMENSIONAL_MODELS_FOR_PROJECT](state: State, payload: number) {
      if (state.models && payload > 0) {
        for (let index = state.models.length - 1; index >= 0; index--) {
          if (state.models[index].projectId === payload)
            state.models.splice(index, 1);
        }
      }
    },

    [MutationTypes.SET_LOADING_THREE_DIMENSIONAL_MODELS](state: State, payload: boolean) {
      state.loadingModels = payload;
      state.loadingData = payload;
    },

    // Accounts
    [MutationTypes.SET_ACCOUNTS](state: State, payload: AccountModel[]) {
      state.accounts = payload;
    },

    [MutationTypes.ADD_ACCOUNT](state: State, payload: AccountModel) {
      if (state.accounts && payload && payload.id && payload.id.length > 0) {
        const account = state.accounts.find(account => account.id === payload.id);

        if (account == undefined)
          state.accounts.push(payload);
      }
    },

    [MutationTypes.UPDATE_ACCOUNT](state: State, payload: AccountModel) {
      if (state.accounts) {
        const account = state.accounts.find(account => account.id === payload.id);

        if (account) {
          account.role = payload.role;
          account.email = payload.email;
          account.firstName = payload.firstName;
          account.lastName = payload.lastName;
        }
      }
    },

    [MutationTypes.SET_LOADING_ACCOUNTS](state: State, payload: boolean) {
      state.loadingAccounts = payload;
      state.loadingData = payload;
    },

    // User
    [MutationTypes.SET_IS_LOGGED](state: State, payload: boolean) {
      state.isLogged = payload;

      if (state.isLogged == false) {
        state.jwt.resetData();
        state.projects = undefined;
        state.sessions = undefined;
        state.models = undefined;
        state.accounts = undefined;
      }
    },

    [MutationTypes.SET_JWT_DATA](state: State, payload: string) {
      state.jwt.parseJwt(payload);
    },

    [MutationTypes.SET_CALLING_API](state: State, payload: boolean) {
      state.callingApi = payload;
    },
  },

  actions: {
    // Global loading
    async [ActionTypes.LOAD_ALL_DATA]({ state, dispatch }) {
      try {
        if (state.projects === undefined)
          await dispatch(ActionTypes.GET_PROJECTS);

        if (state.sessions === undefined)
          await dispatch(ActionTypes.GET_PRESENTATION_SESSIONS);

        if (state.models === undefined)
          await dispatch(ActionTypes.GET_THREE_DIMENSIONAL_MODELS);

        if (state.accounts === undefined) {
          if (state.jwt.isAdmin())
            await dispatch(ActionTypes.GET_ACCOUNTS);
          else
            await dispatch(ActionTypes.GET_ACCOUNT);
        }
      }
      catch (error) {
        return Promise.reject(error);
      }
    },

    // Projects
    async [ActionTypes.GET_PROJECTS]({ state, commit, dispatch }) {
      try {
        commit(MutationTypes.SET_LOADING_PROJECTS, true);
        await dispatch(ActionTypes.INTERNAL_REFRESH_TOKEN);
        const projects: ProjectModel[] = await ProjectsService.getAll(state.jwt.authHeader());
        commit(MutationTypes.SET_LOADING_PROJECTS, false);
        commit(MutationTypes.SET_PROJECTS, projects);
        return Promise.resolve(projects);
      }
      catch (error) {
        commit(MutationTypes.SET_LOADING_PROJECTS, false);
        return Promise.reject(parseHttpExtendedResult(error));
      }
    },

    async [ActionTypes.ADD_PROJECT]({ state, commit, dispatch }, payload: ProjectModel) {
      try {
        await dispatch(ActionTypes.INTERNAL_REFRESH_TOKEN);
        const project: ProjectModel | undefined = await ProjectsService.add(payload, state.jwt.authHeader());
        commit(MutationTypes.ADD_PROJECT, project);
        return Promise.resolve(project);
      }
      catch (error) {
        return Promise.reject(parseHttpExtendedResult(error));
      }
    },

    async [ActionTypes.UPDATE_PROJECT]({ state, commit, dispatch }, payload: ProjectModel) {
      try {
        await dispatch(ActionTypes.INTERNAL_REFRESH_TOKEN);
        const project: ProjectModel | undefined = await ProjectsService.update(payload, state.jwt.authHeader());
        commit(MutationTypes.UPDATE_PROJECT, project);
        return Promise.resolve(project);
      }
      catch (error) {
        return Promise.reject(parseHttpExtendedResult(error));
      }
    },

    async [ActionTypes.DELETE_PROJECT]({ state, commit, dispatch }, payload: number) {
      try {
        await dispatch(ActionTypes.INTERNAL_REFRESH_TOKEN);
        const status: boolean = await ProjectsService.delete(payload, state.jwt.authHeader());
        if (status) {
          commit(MutationTypes.DELETE_THREE_DIMENSIONAL_MODELS_FOR_PROJECT, payload);
          commit(MutationTypes.DELETE_PRESENTATION_SESSIONS_FOR_PROJECT, payload);
          commit(MutationTypes.DELETE_PROJECT, payload);
        }
        return Promise.resolve(status);
      }
      catch (error) {
        return Promise.reject(parseHttpExtendedResult(error));
      }
    },

    // Presentation sessions
    async [ActionTypes.GET_PRESENTATION_SESSIONS]({ state, commit, dispatch }) {
      try {
        commit(MutationTypes.SET_LOADING_PRESENTATION_SESSIONS, true);
        await dispatch(ActionTypes.INTERNAL_REFRESH_TOKEN);
        const sessions: PresentationSessionModel[] = await PresentationSessionService.getAll(state.jwt.authHeader());
        commit(MutationTypes.SET_LOADING_PRESENTATION_SESSIONS, false);
        commit(MutationTypes.SET_PRESENTATION_SESSIONS, sessions);
        return Promise.resolve(sessions);
      }
      catch (error) {
        commit(MutationTypes.SET_LOADING_PRESENTATION_SESSIONS, false);
        return Promise.reject(parseHttpExtendedResult(error));
      }
    },

    async [ActionTypes.ADD_PRESENTATION_SESSION]({ state, commit, dispatch }, payload: PresentationSessionModel) {
      try {
        await dispatch(ActionTypes.INTERNAL_REFRESH_TOKEN);
        const session: PresentationSessionModel | undefined = await PresentationSessionService.add(payload, state.jwt.authHeader());
        commit(MutationTypes.ADD_PRESENTATION_SESSION, session);
        return Promise.resolve(session);
      }
      catch (error) {
        return Promise.reject(parseHttpExtendedResult(error));
      }
    },

    async [ActionTypes.UPDATE_PRESENTATION_SESSION]({ state, commit, dispatch }, payload: PresentationSessionModel) {
      try {
        await dispatch(ActionTypes.INTERNAL_REFRESH_TOKEN);
        const session: PresentationSessionModel | undefined = await PresentationSessionService.update(payload, state.jwt.authHeader());
        commit(MutationTypes.UPDATE_PRESENTATION_SESSION, session);
        return Promise.resolve(session);
      }
      catch (error) {
        return Promise.reject(parseHttpExtendedResult(error));
      }
    },

    async [ActionTypes.DELETE_PRESENTATION_SESSION]({ state, commit, dispatch }, payload: number) {
      try {
        await dispatch(ActionTypes.INTERNAL_REFRESH_TOKEN);
        const status: boolean = await PresentationSessionService.delete(payload, state.jwt.authHeader());
        if (status) {
          commit(MutationTypes.DELETE_PRESENTATION_SESSION, payload);
        }
        return Promise.resolve(status);
      }
      catch (error) {
        return Promise.reject(parseHttpExtendedResult(error));
      }
    },

    async [ActionTypes.GET_RUNNING_PRESENTATION_SESSIONS]({ state, dispatch }) {
      try {
        await dispatch(ActionTypes.INTERNAL_REFRESH_TOKEN);
        const runningSessions: PixelFrontEndContainerInfoModel[] = await PresentationSessionService.getRunningSessions(state.jwt.authHeader());
        return Promise.resolve(runningSessions);
      }
      catch (error) {
        return Promise.reject(parseHttpExtendedResult(error));
      }
    },

    async [ActionTypes.START_PRESENTATION_SESSION]({ state, dispatch }, payload: PresentationSessionModel) {
      try {
        await dispatch(ActionTypes.INTERNAL_REFRESH_TOKEN);
        await PresentationSessionService.startSession(payload.id, state.jwt.authHeader());
        return Promise.resolve();
      }
      catch (error) {
        return Promise.reject(parseHttpExtendedResult(error));
      }
    },

    async [ActionTypes.STOP_PRESENTATION_SESSION]({ state, dispatch }, payload: number) {
      try {
        await dispatch(ActionTypes.INTERNAL_REFRESH_TOKEN);
        await PresentationSessionService.stopSession(payload, state.jwt.authHeader());
        return Promise.resolve();
      }
      catch (error) {
        return Promise.reject(parseHttpExtendedResult(error));
      }
    },

    async [ActionTypes.GET_PLAYER_MACHINES_STATUS]({ state, dispatch }) {
      try {
        await dispatch(ActionTypes.INTERNAL_REFRESH_TOKEN);
        const playerMachinesStatus: PlayerMachineModel[] = await PresentationSessionService.getPlayerMachinesStatus(state.jwt.authHeader());
        return Promise.resolve(playerMachinesStatus);
      }
      catch (error) {
        return Promise.reject(parseHttpExtendedResult(error));
      }
    },

    async [ActionTypes.RESET_PLAYER_MACHINES_STATUS]({ state, dispatch }) {
      try {
        await dispatch(ActionTypes.INTERNAL_REFRESH_TOKEN);
        await PresentationSessionService.resetPlayerMachinesStatus(state.jwt.authHeader());
        return Promise.resolve();
      }
      catch (error) {
        return Promise.reject(parseHttpExtendedResult(error));
      }
    },

    // 3D models
    async [ActionTypes.GET_THREE_DIMENSIONAL_MODELS]({ state, commit, dispatch }) {
      try {
        commit(MutationTypes.SET_LOADING_THREE_DIMENSIONAL_MODELS, true);
        await dispatch(ActionTypes.INTERNAL_REFRESH_TOKEN);
        const models: ThreeDimensionalModel[] = await ThreeDimensionalModelService.getAll(state.jwt.authHeader());
        commit(MutationTypes.SET_LOADING_THREE_DIMENSIONAL_MODELS, false);
        commit(MutationTypes.SET_THREE_DIMENSIONAL_MODELS, models);
        return Promise.resolve(models);
      }
      catch (error) {
        commit(MutationTypes.SET_LOADING_THREE_DIMENSIONAL_MODELS, false);
        return Promise.reject(parseHttpExtendedResult(error));
      }
    },

    async [ActionTypes.ADD_THREE_DIMENSIONAL_MODEL]({ state, commit, dispatch }, payload: ThreeDimensionalModel) {
      try {
        await dispatch(ActionTypes.INTERNAL_REFRESH_TOKEN);
        const model: ThreeDimensionalModel | undefined = await ThreeDimensionalModelService.add(payload, state.jwt.authHeader());
        commit(MutationTypes.ADD_THREE_DIMENSIONAL_MODEL, model);
        return Promise.resolve(model);
      }
      catch (error) {
        return Promise.reject(parseHttpExtendedResult(error));
      }
    },

    async [ActionTypes.UPDATE_THREE_DIMENSIONAL_MODEL]({ state, commit, dispatch }, payload: ThreeDimensionalModel) {
      try {
        await dispatch(ActionTypes.INTERNAL_REFRESH_TOKEN);
        const model: ThreeDimensionalModel | undefined = await ThreeDimensionalModelService.update(payload, state.jwt.authHeader());
        commit(MutationTypes.UPDATE_THREE_DIMENSIONAL_MODEL, model);
        return Promise.resolve(model);
      }
      catch (error) {
        return Promise.reject(parseHttpExtendedResult(error));
      }
    },

    async [ActionTypes.DELETE_THREE_DIMENSIONAL_MODEL]({ state, commit, dispatch }, payload: number) {
      try {
        await dispatch(ActionTypes.INTERNAL_REFRESH_TOKEN);
        const status: boolean = await ThreeDimensionalModelService.delete(payload, state.jwt.authHeader());
        if (status) {
          commit(MutationTypes.DELETE_THREE_DIMENSIONAL_MODEL, payload);
        }
        return Promise.resolve(status);
      }
      catch (error) {
        return Promise.reject(parseHttpExtendedResult(error));
      }
    },

    async [ActionTypes.UPLOAD_THREE_DIMENSIONAL_MODEL]({ state, commit, dispatch }, payload: ThreeDimensionalModelUploadPayload) {
      if (state.jwt.data) {
        try {
          await dispatch(ActionTypes.INTERNAL_REFRESH_TOKEN);
          const model: ThreeDimensionalModel | undefined = await ThreeDimensionalModelService.upload(payload, state.jwt.data.authToken);
          commit(MutationTypes.UPDATE_THREE_DIMENSIONAL_MODEL, model);
          return Promise.resolve(model);
        }
        catch (error) {
          return Promise.reject(parseHttpExtendedResult(error));
        }
      }
    },

    // Accounts
    async [ActionTypes.GET_ACCOUNTS]({ state, commit, dispatch }) {
      try {
        commit(MutationTypes.SET_LOADING_ACCOUNTS, true);
        await dispatch(ActionTypes.INTERNAL_REFRESH_TOKEN);
        const accounts: AccountModel[] = await AccountsService.getAll(state.jwt.authHeader());
        commit(MutationTypes.SET_LOADING_ACCOUNTS, false);
        commit(MutationTypes.SET_ACCOUNTS, accounts);
        return Promise.resolve(accounts);
      }
      catch (error) {
        commit(MutationTypes.SET_LOADING_ACCOUNTS, false);
        return Promise.reject(parseHttpExtendedResult(error));
      }
    },

    async [ActionTypes.GET_ACCOUNT]({ state, commit, dispatch }) {
      if (state.jwt.data) {
        try {
          commit(MutationTypes.SET_LOADING_ACCOUNTS, true);
          await dispatch(ActionTypes.INTERNAL_REFRESH_TOKEN);
          const account: AccountModel | undefined = await AccountsService.get(state.jwt.data?.id, state.jwt.authHeader());
          commit(MutationTypes.SET_LOADING_ACCOUNTS, false);
          commit(MutationTypes.SET_ACCOUNTS, [account]);
          return Promise.resolve(account);
        }
        catch (error) {
          commit(MutationTypes.SET_LOADING_ACCOUNTS, false);
          return Promise.reject(parseHttpExtendedResult(error));
        }
      }
    },

    async [ActionTypes.ADD_ACCOUNT]({ state, commit, dispatch }, payload: RegistrationModel) {
      try {
        await dispatch(ActionTypes.INTERNAL_REFRESH_TOKEN);
        const account: AccountModel | undefined = await AccountsService.add(payload, state.jwt.authHeader());
        commit(MutationTypes.ADD_ACCOUNT, account);
        return Promise.resolve(account);
      }
      catch (error) {
        return Promise.reject(parseHttpExtendedResult(error));
      }
    },

    async [ActionTypes.UPDATE_ACCOUNT]({ state, commit, dispatch }, payload: AccountModel) {
      try {
        await dispatch(ActionTypes.INTERNAL_REFRESH_TOKEN);
        const account: AccountModel | undefined = await AccountsService.update(payload, state.jwt.authHeader());
        commit(MutationTypes.UPDATE_ACCOUNT, account);
        return Promise.resolve(account);
      }
      catch (error) {
        return Promise.reject(parseHttpExtendedResult(error));
      }
    },

    async [ActionTypes.UPDATE_ACCOUNT_PASSWORD]({ state, dispatch }, payload: ChangePasswordModel) {
      try {
        await dispatch(ActionTypes.INTERNAL_REFRESH_TOKEN);
        await AccountsService.updatePassword(payload, state.jwt.authHeader());
        return Promise.resolve();
      }
      catch (error) {
        return Promise.reject(parseHttpExtendedResult(error));
      }
    },

    // User
    [ActionTypes.LOGIN]({ commit }, payload: CredentialsModel) {
      commit(MutationTypes.SET_CALLING_API, true);

      return AuthService.login(payload).then(
        user => {
          commit(MutationTypes.SET_CALLING_API, false);
          commit(MutationTypes.SET_IS_LOGGED, true);
          commit(MutationTypes.SET_JWT_DATA, user.authToken);
          return Promise.resolve(user);
        },
        error => {
          commit(MutationTypes.SET_CALLING_API, false);
          commit(MutationTypes.SET_IS_LOGGED, false);
          return Promise.reject(parseHttpExtendedResult(error));
        }
      );
    },

    [ActionTypes.LOGOUT]({ commit }) {
      commit(MutationTypes.SET_CALLING_API, true);

      return AuthService.logout().then(
        () => {
          commit(MutationTypes.SET_CALLING_API, false);
          commit(MutationTypes.SET_IS_LOGGED, false);
          return Promise.resolve();
        },
        error => {
          commit(MutationTypes.SET_CALLING_API, false);
          commit(MutationTypes.SET_IS_LOGGED, false);
          return Promise.reject(parseHttpExtendedResult(error));
        }
      );
    },

    [ActionTypes.REQUEST_TOKEN]({ commit }, payload: string) {
      commit(MutationTypes.SET_CALLING_API, true);

      return AuthService.requestToken(payload).then(
        user => {
          commit(MutationTypes.SET_CALLING_API, false);
          commit(MutationTypes.SET_IS_LOGGED, true);
          commit(MutationTypes.SET_JWT_DATA, user.authToken);
          return Promise.resolve(user);
        },
        error => {
          commit(MutationTypes.SET_CALLING_API, false);
          commit(MutationTypes.SET_IS_LOGGED, false);
          return Promise.reject(parseHttpExtendedResult(error));
        }
      );
    },

    [ActionTypes.INTERNAL_REFRESH_TOKEN]({ state, commit }) {
      if (state.jwt.isTokenExpired()) {
        return AuthService.refreshToken().then(
          user => {
            commit(MutationTypes.SET_IS_LOGGED, true);
            commit(MutationTypes.SET_JWT_DATA, user.authToken);
            return Promise.resolve();
          },
          error => {
            commit(MutationTypes.SET_IS_LOGGED, false);
            return Promise.reject(error);
          }
        );
      }
      else
        return Promise.resolve();
    },
  },
});

// Define your own 'useStore' composition function.
export function useStore() {
  return baseUseStore(key);
}
