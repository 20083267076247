export class PixelFrontEndContainerInfoModel {
  presentationSessionId: number;
  sessionJoinToken: string;
  userName: string;
  createdAt: Date;

  constructor (presentationSessionId: number, sessionJoinToken: string, userName: string, createdAt: Date)
  { 
    this.presentationSessionId = presentationSessionId;
    this.sessionJoinToken = sessionJoinToken;
    this.userName = userName;
    this.createdAt = createdAt;
  }
}
