import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Signin from '@/views/Signin.vue';
import Meet from '@/views/Meet.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/signin',
    name: 'Signin',
    component: Signin
  },
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard.vue')
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('@/views/Projects.vue')
  },
  {
    path: '/projects/:id',
    name: 'ProjectDetails',
    component: () => import('@/views/ProjectDetails.vue')
  },
  {
    path: '/sessions',
    name: 'Sessions',
    component: () => import('@/views/Sessions.vue')
  },
  {
    path: '/accounts',
    name: 'Accounts',
    component: () => import('@/views/Accounts.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('@/views/Account.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('@/views/Admin.vue')
  },
  {
    path: '/meet',
    name: 'Meet',
    component: Meet
  },
  { // Redirect all unknown routes to dashboard.
    path: '/:pathMatch(.*)*',
    redirect: { name: 'Dashboard' }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active'
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/signin', '/register', '/meet'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // Trying to access a restricted page + not logged in:
  // redirect to sign in page.
  if (authRequired && !loggedIn)
    next('/signin');
  else
    next();
});

export default router;
