import axios from 'axios';
import { AuthHeader } from '@/services/jwt-service';
import { State } from '@/store/index';
import { ListCardItem } from '@/models/list-card-item';
import { AccountModel } from '@/models/account-model';
import { RegistrationModel } from '@/models/registration-model';
import { ChangePasswordModel } from '@/models/change-password-model';
import ConfigProvider from '@/services/config-provider';

const API_BASE_URL = ConfigProvider.value('apiBaseUri') + '/accounts';

class AccountsService {
  async getAll(headers: AuthHeader) {
    return await axios
      .get(API_BASE_URL, { headers })
      .then(response => {
        if (response.data)
          return (response.data.items as Array<AccountModel>)
            .map(item => this.deserialize(item));

        return [];
      });
  }

  async get(id: string, headers: AuthHeader) {
    return await axios
      .get(API_BASE_URL + '/' + id, { headers })
      .then(response => {
        if (response.data)
          return this.deserialize(response.data);

        return undefined;
      });
  }

  async add(account: RegistrationModel, headers: AuthHeader) {
    return await axios
      .post(API_BASE_URL + '/create', account, { headers })
      .then(response => {
        if (response.data)
          return this.deserialize(response.data);
        
        return undefined;
      });
  }

  async update(account: AccountModel, headers: AuthHeader) {
    return await axios
      .put(API_BASE_URL + '/' + account.id, account, { headers })
      .then(response => {
        if (response.data)
          return this.deserialize(response.data);
        
        return undefined;
      });
  }

  async updatePassword(changePassword: ChangePasswordModel, headers: AuthHeader) {
    return await axios
      .put(API_BASE_URL + '/' + changePassword.id + '/password', changePassword, { headers });
  }

  getAccountById(state: State, id: string): AccountModel | undefined {
    if (state.accounts)
      return state.accounts.find(account => account.id === id);
    
    return undefined;
  }

  accountsList(state: State): ListCardItem[] {
    if (state.accounts)
      return state.accounts
      .slice()
      .sort((a, b) => (a.firstName + ' ' + a.lastName).localeCompare(b.firstName + ' ' + b.lastName))
      .map(item => this.fillListCardItem(item));
    
    return [];
  }

  createNewAccount(): AccountModel {
    return new AccountModel('', 'User', '', '', '');
  }

  private fillListCardItem(item: AccountModel): ListCardItem {
    return new ListCardItem(item.id, item.firstName + ' ' + item.lastName, item.role + ' | ' + item.email);
  }

  private deserialize(item: AccountModel): AccountModel {
    return new AccountModel(
      item.id,
      item.role,
      item.email,
      item.firstName,
      item.lastName);
  }
}

export default new AccountsService();
