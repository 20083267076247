
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/action-types';
import { CredentialsModel } from '@/models/credentials-model';
import HttpExtendedResultAlert from '@/components/HttpExtendedResultAlert.vue';
import ConfigProvider from '@/services/config-provider';

export default defineComponent({
  name: 'Signin',
  components: {
    HttpExtendedResultAlert,
  },

  setup() {
    const store = useStore();
    return { store };
  },

  mounted() {
    this.manageExternalSignin();
  },

  data: () => ({
    email: ConfigProvider.value('defaultUserName'),
    password: ConfigProvider.value('defaultPassword'),
    casBaseUri: ConfigProvider.value('casBaseUri'),
    testRtcUri: ConfigProvider.value('testRtcUri'),
    unsecuredRefreshToken: ConfigProvider.value('unsecuredRefreshToken') === 'true', // Config values are always strings.

    externalSigninLoading: false,
    error: null,
  }),

  computed: {
    callingApi(): boolean { return this.store.state.callingApi; },
    hasExternalSignin(): boolean { return this.casBaseUri.length > 0; },
    hasTestRtcUri(): boolean { return this.testRtcUri.length > 0; },
  },

  methods: {
    onSignin() {
      this.error = null;
      const payload = new CredentialsModel(this.email, this.password);
      payload.unsecuredRefreshToken = this.unsecuredRefreshToken;
      
      this.store.dispatch(ActionTypes.LOGIN, payload).then(
        () => this.$router.push({ name: 'Dashboard' }),
        error => this.error = error
      );
    },

    onExternalSignin() {
      const redirectUri = window.location.href.split('?')[0];
      const externalUri = this.casBaseUri + '/begin?redirectUri=' + encodeURIComponent(redirectUri);

      window.location.replace(externalUri);
    },

    manageExternalSignin() {
      const authorizationCode = this.$route.query.code;

      if (authorizationCode && authorizationCode.length > 0) {
        this.externalSigninLoading = true;

        this.store.dispatch(ActionTypes.REQUEST_TOKEN, authorizationCode).then(
          () => {
            this.externalSigninLoading = false;
            this.$router.push({ name: 'Dashboard' });
          },
          error => { 
            this.externalSigninLoading = false;
            this.error = error;
          }
        );
      }
    },
  },
});
