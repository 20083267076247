<template>
  <div class="container">
    <div class="d-flex justify-content-center">
      <img alt="Assystem logo" src="@/assets/logo.png">
    </div>

    <h1 class="text-center mt-4">Welcome to SecuREview</h1>
    <h4 v-if="!externalSigninLoading" class="text-center mt-4">Please sign in to continue</h4>

    <div class="border border-secondary rounded mt-4 mb-4 p-4 login-box">
      <div v-if="externalSigninLoading" class="external-signin">
        <div class="d-flex justify-content-center icon"><font-awesome-icon icon="spinner" spin /></div>
        <div class="d-flex justify-content-center mt-3 text">Signing in to external server...</div>
      </div>

      <form v-else>
        <div class="form-group">
          <label for="email">Email</label>
          <input v-focus v-model="email" type="email" class="form-control" id="email" placeholder="Enter your email">
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input v-model="password" type="password" class="form-control" id="password" placeholder="Enter your password">
        </div>

        <HttpExtendedResultAlert v-if="error" v-bind:result="error" />

        <div class="d-flex justify-content-between">
          <button type="submit" class="btn btn-primary" @click.prevent="onSignin()" v-bind:disabled="callingApi">
            <font-awesome-icon v-if="callingApi" icon="spinner" spin />
            Sign in
          </button>
          <div v-if="hasExternalSignin" class="d-flex align-items-end">
            <a href="#" @click.prevent="onExternalSignin()">Sign in with Assystem's server</a>
          </div>
        </div>
      </form>
    </div>

    <div v-if="hasTestRtcUri" class="border border-secondary rounded mt-4 mb-4 p-4 login-box">
      <h6><strong><font-awesome-icon icon="wrench" /> WebRTC Troubleshooter</strong></h6>
      <div>
        Test your computer and network settings
        <a v-bind:href="testRtcUri" target="_blank">here</a>
        before logging for the first time.
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/action-types';
import { CredentialsModel } from '@/models/credentials-model';
import HttpExtendedResultAlert from '@/components/HttpExtendedResultAlert.vue';
import ConfigProvider from '@/services/config-provider';

export default defineComponent({
  name: 'Signin',
  components: {
    HttpExtendedResultAlert,
  },

  setup() {
    const store = useStore();
    return { store };
  },

  mounted() {
    this.manageExternalSignin();
  },

  data: () => ({
    email: ConfigProvider.value('defaultUserName'),
    password: ConfigProvider.value('defaultPassword'),
    casBaseUri: ConfigProvider.value('casBaseUri'),
    testRtcUri: ConfigProvider.value('testRtcUri'),
    unsecuredRefreshToken: ConfigProvider.value('unsecuredRefreshToken') === 'true', // Config values are always strings.

    externalSigninLoading: false,
    error: null,
  }),

  computed: {
    callingApi(): boolean { return this.store.state.callingApi; },
    hasExternalSignin(): boolean { return this.casBaseUri.length > 0; },
    hasTestRtcUri(): boolean { return this.testRtcUri.length > 0; },
  },

  methods: {
    onSignin() {
      this.error = null;
      const payload = new CredentialsModel(this.email, this.password);
      payload.unsecuredRefreshToken = this.unsecuredRefreshToken;
      
      this.store.dispatch(ActionTypes.LOGIN, payload).then(
        () => this.$router.push({ name: 'Dashboard' }),
        error => this.error = error
      );
    },

    onExternalSignin() {
      const redirectUri = window.location.href.split('?')[0];
      const externalUri = this.casBaseUri + '/begin?redirectUri=' + encodeURIComponent(redirectUri);

      window.location.replace(externalUri);
    },

    manageExternalSignin() {
      const authorizationCode = this.$route.query.code;

      if (authorizationCode && authorizationCode.length > 0) {
        this.externalSigninLoading = true;

        this.store.dispatch(ActionTypes.REQUEST_TOKEN, authorizationCode).then(
          () => {
            this.externalSigninLoading = false;
            this.$router.push({ name: 'Dashboard' });
          },
          error => { 
            this.externalSigninLoading = false;
            this.error = error;
          }
        );
      }
    },
  },
});
</script>

<style scoped lang="scss">
.login-box {
  max-width: 460px;
  margin: auto;
  background-color: #F6F6F6;
}

.external-signin {
  .icon {
    font-size: 2rem;
  }

  .text {
    font-size: 1.2rem;
  }
}
</style>
