
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/action-types';
import PresentationSessionService from '@/services/presentation-sessions-service';
import { PresentationSessionModel } from '@/models/presentation-session-model';
import HttpExtendedResultAlert from '@/components/HttpExtendedResultAlert.vue';
import { HttpExtendedResult } from '@/services/http-extended-result';

export default defineComponent({
  name: 'Meet',
  components: {
    HttpExtendedResultAlert,
  },

  setup() {
    const store = useStore();
    return { store };
  },

  data: () => ({
    joinToken: '',
    loggedIn: false,
    isOwner: false,
    sessionReady: false,
    sessionWasReady: false,
    checkSessionEnabled: true,
    error: undefined as HttpExtendedResult | undefined,
    goBackMessage: 'Go to sign in page',
  }),

  mounted() {
    if (this.readJoinToken()) {
      this.loggedIn = localStorage.getItem('user') != null;

      if (this.loggedIn) {
        this.goBackMessage = 'Go back to dashboard';

        this.store.dispatch(ActionTypes.LOAD_ALL_DATA).then(
          () => {
            this.isOwner = this.getSessionByJoinToken() != null;

            if (this.isOwner) { // It's the owner of the current session.
              setTimeout(() => this.startSession(), 100);
            }
            else { // It's a guest.
              setTimeout(() => this.checkSessionIsReady(), 100);
            }
          },
          undefined
        );
      }
      else { // It's a guest.
        setTimeout(() => this.checkSessionIsReady(), 100);
      }
    }
    else {
      this.$router.push({ name: 'Signin' });
    }
  },

  beforeUnmount() {
    this.checkSessionEnabled = false;
  },

  methods: {
    readJoinToken(): boolean {
      const joinToken = this.$route.query.joinToken as string;

      if (joinToken && joinToken.length === 73) {
        this.joinToken = joinToken;
        return true;
      }

      return false;
    },

    startSession() {
      const session = this.getSessionByJoinToken();

      if (session) {
        this.store.dispatch(ActionTypes.START_PRESENTATION_SESSION, session).then(
          () => this.checkSessionIsReady(),
          error => this.error = error);
      }
      else
        this.$router.push({ name: 'Signin' });
    },

    checkSessionIsReady() {
      if (!this.checkSessionEnabled)
        return;

      PresentationSessionService.checkSessionIsReady(this.joinToken)
        .then(isReady => {
          this.sessionReady = isReady;

          if (isReady) {
            this.sessionWasReady = true;
          }
          else {
            if (this.isOwner && this.sessionWasReady)
              this.$router.push({ name: 'Dashboard' });
          }

          setTimeout(() => this.checkSessionIsReady(), 1000);
        });
    },

    openStreams() {
      PresentationSessionService.openSession(this.joinToken);
    },

    gotoBack() {
      if (this.loggedIn)
        this.$router.push({ name: 'Dashboard' });
      else
        this.$router.push({ name: 'Signin' });
    },
    
    getSessionByJoinToken(): PresentationSessionModel {
      return this.store.getters.getSessionByJoinToken(this.joinToken);
    },
  },
});
