export class ListCardItem {
  id: string;
  title: string;
  subTitle?: string;

  constructor (id: string, title: string, subTitle?: string)
  { 
    this.id = id;
    this.title = title;
    this.subTitle = subTitle;
  }
}
