import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { store, key } from './store';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faPlusCircle, faChalkboardTeacher, faCubes, faFolderOpen, faHdd, faEdit,
         faSpinner, faImage, faFileUpload, faCheck, faUsers, faPlayCircle, faSyncAlt,
         faStopCircle, faServer, faWrench } from '@fortawesome/free-solid-svg-icons';
library.add(faPlusCircle);
library.add(faChalkboardTeacher);
library.add(faCubes);
library.add(faFolderOpen);
library.add(faHdd);
library.add(faEdit);
library.add(faSpinner);
library.add(faImage);
library.add(faFileUpload);
library.add(faCheck);
library.add(faUsers);
library.add(faPlayCircle);
library.add(faSyncAlt);
library.add(faStopCircle);
library.add(faServer);
library.add(faWrench);

const app = createApp(App).use(store, key).use(router);
app.component('font-awesome-icon', FontAwesomeIcon);

// Register a global custom directive called 'v-focus'.
app.directive('focus', {
  mounted(el) {
    el.focus();
  }
});

app.mount('#app')
