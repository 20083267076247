<template>
  <div class="container">
    <div class="d-flex justify-content-center">
      <img alt="Assystem logo" src="@/assets/logo.png" width="280" height="196">
    </div>

    <h1 class="text-center mt-4">Welcome to SecuREview</h1>

    <div v-if="sessionReady">
      <h4 class="text-center mt-4">You were invited to join a live and secure meeting.</h4>

      <div class="border border-secondary rounded mt-4 mb-4 p-4 centered-box">
        <div class="loading-message">
          <div class="d-flex justify-content-center text">
            When you are ready, click on the button below to join.
          </div>
          <div class="d-flex justify-content-center mt-4">
            <button class="btn btn-outline-primary btn-lg" @click.prevent="openStreams()">Join the meeting</button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h4 class="text-center mt-4">Establishing a live and secure meeting.</h4>

      <div class="border border-secondary rounded mt-4 mb-4 p-4 centered-box">
        <div v-if="error" class="loading-message">
          <div class="d-flex justify-content-center mb-4 text">Oops!<br>We were not able to start your meeting. Maybe all available sessions are already used.</div>
          <HttpExtendedResultAlert v-bind:result="error" />
        </div>
        <div v-else class="loading-message">
          <div class="d-flex justify-content-center icon"><font-awesome-icon icon="spinner" spin /></div>
          <div class="d-flex justify-content-center mt-3 text">Your session is starting. Please wait until it's ready...</div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center mt-4">
      <a href="#" @click.prevent="gotoBack()">{{ goBackMessage }}</a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/action-types';
import PresentationSessionService from '@/services/presentation-sessions-service';
import { PresentationSessionModel } from '@/models/presentation-session-model';
import HttpExtendedResultAlert from '@/components/HttpExtendedResultAlert.vue';
import { HttpExtendedResult } from '@/services/http-extended-result';

export default defineComponent({
  name: 'Meet',
  components: {
    HttpExtendedResultAlert,
  },

  setup() {
    const store = useStore();
    return { store };
  },

  data: () => ({
    joinToken: '',
    loggedIn: false,
    isOwner: false,
    sessionReady: false,
    sessionWasReady: false,
    checkSessionEnabled: true,
    error: undefined as HttpExtendedResult | undefined,
    goBackMessage: 'Go to sign in page',
  }),

  mounted() {
    if (this.readJoinToken()) {
      this.loggedIn = localStorage.getItem('user') != null;

      if (this.loggedIn) {
        this.goBackMessage = 'Go back to dashboard';

        this.store.dispatch(ActionTypes.LOAD_ALL_DATA).then(
          () => {
            this.isOwner = this.getSessionByJoinToken() != null;

            if (this.isOwner) { // It's the owner of the current session.
              setTimeout(() => this.startSession(), 100);
            }
            else { // It's a guest.
              setTimeout(() => this.checkSessionIsReady(), 100);
            }
          },
          undefined
        );
      }
      else { // It's a guest.
        setTimeout(() => this.checkSessionIsReady(), 100);
      }
    }
    else {
      this.$router.push({ name: 'Signin' });
    }
  },

  beforeUnmount() {
    this.checkSessionEnabled = false;
  },

  methods: {
    readJoinToken(): boolean {
      const joinToken = this.$route.query.joinToken as string;

      if (joinToken && joinToken.length === 73) {
        this.joinToken = joinToken;
        return true;
      }

      return false;
    },

    startSession() {
      const session = this.getSessionByJoinToken();

      if (session) {
        this.store.dispatch(ActionTypes.START_PRESENTATION_SESSION, session).then(
          () => this.checkSessionIsReady(),
          error => this.error = error);
      }
      else
        this.$router.push({ name: 'Signin' });
    },

    checkSessionIsReady() {
      if (!this.checkSessionEnabled)
        return;

      PresentationSessionService.checkSessionIsReady(this.joinToken)
        .then(isReady => {
          this.sessionReady = isReady;

          if (isReady) {
            this.sessionWasReady = true;
          }
          else {
            if (this.isOwner && this.sessionWasReady)
              this.$router.push({ name: 'Dashboard' });
          }

          setTimeout(() => this.checkSessionIsReady(), 1000);
        });
    },

    openStreams() {
      PresentationSessionService.openSession(this.joinToken);
    },

    gotoBack() {
      if (this.loggedIn)
        this.$router.push({ name: 'Dashboard' });
      else
        this.$router.push({ name: 'Signin' });
    },
    
    getSessionByJoinToken(): PresentationSessionModel {
      return this.store.getters.getSessionByJoinToken(this.joinToken);
    },
  },
});
</script>

<style scoped lang="scss">
.centered-box {
  max-width: 460px;
  margin: auto;
  background-color: #F6F6F6;
}

.loading-message {
  .icon {
    font-size: 2rem;
  }

  .text {
    font-size: 1.2rem;
  }
}
</style>
