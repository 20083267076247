
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/action-types';

export default defineComponent({
  name: 'NavBar',

  setup() {
    const store = useStore();
    return { store };
  },

  computed: {
    callingApi(): boolean { return this.store.state.callingApi; },
    userIsAdmin(): boolean { return this.store.getters.userIsAdmin; },

    // Vue Router doc says: "The default active class matching behavior is inclusive match."
    // In that case, it doesn't work. This little hack solve the problem.
    isProjects(): boolean { return this.$route.path.indexOf('/projects') === 0; },
  },

  methods: {
    onSignout() {
      this.store.dispatch(ActionTypes.LOGOUT).then(
        () => this.$router.push({ name: 'Signin' }),
        (error) => {
          alert(error.title + '\r\nhttp code: ' + error.status);
          this.$router.push({ name: 'Signin' });
        }
      );
    },
  }
});
