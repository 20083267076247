export class RefreshTokenModel {
  refreshToken?: string;
  unsecuredRefreshToken: boolean;

  constructor ()
  {
    const storedUser = localStorage.getItem('user');
    const user = storedUser ? JSON.parse(storedUser) : undefined;
    
    if (user && user.refreshToken && user.refreshToken.length > 0) {
      this.refreshToken = user.refreshToken;
      this.unsecuredRefreshToken = true;
    }
    else
      this.unsecuredRefreshToken = false;
  }
}
