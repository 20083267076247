
import { defineComponent } from 'vue';
import { HttpExtendedResult } from '@/services/http-extended-result';

export default defineComponent({
  name: 'HttpExtendedResultAlert',
  props: {
    result: {
      type: Object as () => HttpExtendedResult,
      required: true
    },
  },

  computed: {
    message(): string {
      let message = this.result.title;

      if (this.result.errors) {
        message += '<ul style="margin-bottom: 0;">';

        for (const key in this.result.errors) {
          for (const item of this.result.errors[key])
            message += '<li>' + item + '</li>';
        }

        message += '</ul>';
      }

      return message;
    }
  },
});
