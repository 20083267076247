export enum ActionTypes {
  LOAD_ALL_DATA = 'LOAD_ALL_DATA',

  GET_PROJECTS = 'GET_PROJECTS',
  ADD_PROJECT = 'ADD_PROJECT',
  UPDATE_PROJECT = 'UPDATE_PROJECT',
  DELETE_PROJECT = 'DELETE_PROJECT',

  GET_PRESENTATION_SESSIONS = 'GET_PRESENTATION_SESSIONS',
  ADD_PRESENTATION_SESSION = 'ADD_PRESENTATION_SESSION',
  UPDATE_PRESENTATION_SESSION = 'UPDATE_PRESENTATION_SESSION',
  DELETE_PRESENTATION_SESSION = 'DELETE_PRESENTATION_SESSION',
  GET_RUNNING_PRESENTATION_SESSIONS = 'GET_RUNNING_PRESENTATION_SESSIONS',
  START_PRESENTATION_SESSION = 'START_PRESENTATION_SESSION',
  STOP_PRESENTATION_SESSION = 'STOP_PRESENTATION_SESSION',
  GET_PLAYER_MACHINES_STATUS = 'GET_PLAYER_MACHINES_STATUS',
  RESET_PLAYER_MACHINES_STATUS = 'RESET_PLAYER_MACHINES_STATUS',

  GET_THREE_DIMENSIONAL_MODELS = 'GET_THREE_DIMENSIONAL_MODELS',
  ADD_THREE_DIMENSIONAL_MODEL = 'ADD_THREE_DIMENSIONAL_MODEL',
  UPDATE_THREE_DIMENSIONAL_MODEL = 'UPDATE_THREE_DIMENSIONAL_MODEL',
  DELETE_THREE_DIMENSIONAL_MODEL = 'DELETE_THREE_DIMENSIONAL_MODEL',
  UPLOAD_THREE_DIMENSIONAL_MODEL = 'UPLOAD_THREE_DIMENSIONAL_MODEL',

  GET_ACCOUNTS = 'GET_ACCOUNTS',
  GET_ACCOUNT = 'GET_ACCOUNT',
  ADD_ACCOUNT = 'ADD_ACCOUNT',
  UPDATE_ACCOUNT = 'UPDATE_ACCOUNT',
  UPDATE_ACCOUNT_PASSWORD = 'UPDATE_ACCOUNT_PASSWORD',

  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  REQUEST_TOKEN = 'REQUEST_TOKEN',
  INTERNAL_REFRESH_TOKEN = 'INTERNAL_REFRESH_TOKEN',
}
