export enum MutationTypes {
  SET_LOADING_PROJECTS = 'SET_LOADING_PROJECTS',
  SET_PROJECTS = 'SET_PROJECTS',
  ADD_PROJECT = 'ADD_PROJECT',
  UPDATE_PROJECT = 'UPDATE_PROJECT',
  DELETE_PROJECT = 'DELETE_PROJECT',

  SET_LOADING_PRESENTATION_SESSIONS = 'SET_LOADING_PRESENTATION_SESSIONS',
  SET_PRESENTATION_SESSIONS = 'SET_PRESENTATION_SESSIONS',
  ADD_PRESENTATION_SESSION = 'ADD_PRESENTATION_SESSION',
  UPDATE_PRESENTATION_SESSION = 'UPDATE_PRESENTATION_SESSION',
  DELETE_PRESENTATION_SESSION = 'DELETE_PRESENTATION_SESSION',
  DELETE_PRESENTATION_SESSIONS_FOR_PROJECT = 'DELETE_PRESENTATION_SESSIONS_FOR_PROJECT',

  SET_LOADING_THREE_DIMENSIONAL_MODELS = 'SET_LOADING_THREE_DIMENSIONAL_MODELS',
  SET_THREE_DIMENSIONAL_MODELS = 'SET_THREE_DIMENSIONAL_MODELS',
  ADD_THREE_DIMENSIONAL_MODEL = 'ADD_THREE_DIMENSIONAL_MODEL',
  UPDATE_THREE_DIMENSIONAL_MODEL = 'UPDATE_THREE_DIMENSIONAL_MODEL',
  DELETE_THREE_DIMENSIONAL_MODEL = 'DELETE_THREE_DIMENSIONAL_MODEL',
  DELETE_THREE_DIMENSIONAL_MODELS_FOR_PROJECT = 'DELETE_THREE_DIMENSIONAL_MODELS_FOR_PROJECT',

  SET_LOADING_ACCOUNTS = 'SET_LOADING_ACCOUNTS',
  SET_ACCOUNTS = 'SET_ACCOUNTS',
  ADD_ACCOUNT = 'ADD_ACCOUNT',
  UPDATE_ACCOUNT = 'UPDATE_ACCOUNT',

  SET_IS_LOGGED = 'SET_IS_LOGGED',
  SET_JWT_DATA = 'SET_JWT_DATA',
  SET_CALLING_API = 'SET_CALLING_API',
}
