import axios from 'axios';
import { AuthHeader } from '@/services/jwt-service';
import { State } from '@/store/index';
import { ListCardItem } from '@/models/list-card-item';
import { ProjectModel } from '@/models/project-model';
import ConfigProvider from '@/services/config-provider';

const API_BASE_URL = ConfigProvider.value('apiBaseUri') + '/projects';

class ProjectsService {
  async getAll(headers: AuthHeader) {
    return await axios
      .get(API_BASE_URL, { headers })
      .then(response => {
        if (response.data)
          return (response.data.items as Array<ProjectModel>)
            .map(item => this.deserialize(item));

        return [];
      });
  }

  async add(project: ProjectModel, headers: AuthHeader) {
    return await axios
      .post(API_BASE_URL, project, { headers })
      .then(response => {
        if (response.data)
          return this.deserialize(response.data);
        
        return undefined;
      });
  }

  async update(project: ProjectModel, headers: AuthHeader) {
    return await axios
      .put(API_BASE_URL + '/' + project.id, project, { headers })
      .then(response => {
        if (response.data)
          return this.deserialize(response.data);
        
        return undefined;
      });
  }

  async delete(id: number, headers: AuthHeader) {
    return await axios
      .delete(API_BASE_URL + '/' + id, { headers })
      .then(response => {
        if (response.status === 200)
          return true;
        
        return false;
      });
  }

  getProjectById(state: State, id: number): ProjectModel | undefined {
    if (state.projects)
      return state.projects.find(project => project.id === id);
    
    return undefined;
  }

  projectsList(state: State): ListCardItem[] {
    if (state.projects)
      return state.projects
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(item => this.fillListCardItem(item));
    
    return [];
  }

  mostRecentProjectsList(state: State): ListCardItem[] {
    if (state.projects)
      return state.projects.slice()
        .reverse().slice(0, 3)
        .map(item => this.fillListCardItem(item));
    
    return [];
  }

  createNewProject(): ProjectModel {
    return new ProjectModel(0, '');
  }

  private fillListCardItem(item: ProjectModel): ListCardItem {
    return new ListCardItem(item.id.toString(), item.name);
  }

  private deserialize(item: ProjectModel): ProjectModel {
    return new ProjectModel(
      item.id,
      item.name,
      item.description,
      item.ownedByUserId);
  }
}

export default new ProjectsService();
