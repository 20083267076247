export class CredentialsModel {
  userName: string;
  password: string;
  unsecuredRefreshToken: boolean;

  constructor (userName: string, password: string)
  { 
    this.userName = userName;
    this.password = password;
    this.unsecuredRefreshToken = false;
  }
}
