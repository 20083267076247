export class PlayerMachineModel {
  name: string;
  isOffline: boolean;

  constructor (name: string, isOffline: boolean)
  { 
    this.name = name;
    this.isOffline = isOffline;
  }
}
